import React from 'react';
import PropTypes from 'prop-types';
import '../../login/Login.scss';

class ChangePasswordForm extends React.Component {
    state = {

        code: '',
        password: '',
        confirmPassword: ''
    };

    componentWillMount() {
        this.loadCode();
    }

    onInputChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = async event => {
        event.preventDefault();
            this.props.onChangePassword(
                this.state.code,
                this.state.password,
                this.state.confirmPassword
            );
    };

    loadCode = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        var code = urlParams.get('code');
        if (code) {
            try {
                this.setState({code: code});
            } catch (error) {
                console.log('error ' + error);
            }
        }
    };

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <div className="">
                    <div className="input-group name">
                        <label htmlFor="name">Codigo *</label>
                        <input
                            id="code"
                            value={this.state.code}
                            onChange={this.onInputChange}
                            required
                            placeholder={'ingrese el codigo recibido'}
                        />
                    </div>
                    <div className="input-group password">
                        <label htmlFor="password">Contraseña *</label>
                        <input
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onInputChange}
                            required
                            placeholder={'ingrese su nueva contraseña'}
                        />
                    </div>
                    <div className="input-group confirmPassword">
                        <label htmlFor="confirmPassword">
                            Confirmar contraseña *
                        </label>
                        <input
                            id="confirmPassword"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={this.onInputChange}
                            required
                            placeholder={'repita su nueva contraseña'}
                        />
                    </div>
                    <button type="submit" className="formButton">
                        Ingresar
                    </button>
                </div>
            </form>
        );
    }
}

ChangePasswordForm.propTypes = {
    onChangePassword: PropTypes.func
};

export default ChangePasswordForm;
