import React, { useState, useEffect } from 'react';
import StatusCards from '../statusCard/StatusCards';
import './OrderWrapper.css';
import { useCookies } from 'react-cookie';
import Card from '../../dashboard/card/Card';
import { getOrders } from '../../../api/utils/getOrder';

const OrderWrapper = () => {
    const [cookies] = useCookies(['token']);
    const [status, setStatus] = useState([]);
    const [sizes, setSizes] = useState([]);
    const [packageData, setPackageData] = useState({});
    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const setOrders = async () => {
            const [status, sizes, results, showData] = await getOrders(cookies);
            setStatus(status);
            setSizes(sizes);
            setPackageData(results);
            setShowData(showData);
            setLoading(false);
        };
        setOrders();
    }, []);

    if (loading) {
        return (
            <div className="order-wrapper align-center">
                <h1 className="order-wrapper__heading not-found"> Cargando... </h1>
            </div>
        );
    }

    if (!showData) {
        return (
            <div className="order-wrapper ">
                <h1 className="order-wrapper__heading not-found">
                    El paquete no fue encontrado
                </h1>
            </div>
        );
    }

    return (
        <div className="order-wrapper">
            <h1 className="order-wrapper__heading">Estado de tu pedido</h1>
            <section className="cards-wrapper">
                <Card
                    order={packageData}
                    statusList={status}
                    sizes={sizes}
                    cardClass={'detail-margin'}
                />
                <StatusCards statusList={status} packageData={packageData} />
            </section>
        </div>
    );
};

export default OrderWrapper;
