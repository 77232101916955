import axios from 'axios';
import config from '../config/config.json';

const axiosInstance = axios.create({
    baseURL: config.development.googleLogin,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
    }
});

export const loginWithGoogle = async tokenId => {
    try {
        const response = await axiosInstance.get(
            `/tokeninfo?id_token=${tokenId}`
        );
        return response.data;
    } catch (error) {
        throw new Error(error.response.data.error_description);
    }
};
