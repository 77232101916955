import React from 'react';
import './styles.css';
import { IoIosCheckmark } from 'react-icons/io';

const NextStatus = ({ log, statusList }) => {
    let statusName = 'Entrega del envío';
    if (statusList) {
        const status = statusList.find(
            status => log.nextStatus === status.value
        );
        statusName = status ? status.name : '';
    }
    return (
        <li className="status-card__list__element">
            <span className="status-card__list__item">
                <i className="status-card__list__item__icon">
                    <IoIosCheckmark
                        className="status-card__list__check disabled"
                        size={22}
                    />
                </i>
            </span>{' '}
            <p className="status-card__list__item__paragraph --deliver ">
                {' '}
                {statusName}
            </p>
        </li>
    );
};

export default NextStatus;
