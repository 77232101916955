import React, { useState } from 'react';
import PastStatus from './ListElements/PastStatus';
import './StatusCard.css';
import CurrentStatus from './ListElements/CurrentStatus';
import NextStatus from './ListElements/NextStatus';
import Modal from 'react-modal';

const StatusCards = ({ statusList, packageData, packageStatus }) => {
    const previousStatus = [];
    const previousStatusIds = [];
    const nextStatus = [];
    const nextStatusIds = [];
    let indexOfCurrentStatusInLogs = packageData.logChangeStatus.length;
    const [modalIndex, setModalIndex] = useState(0);
    const [attemptPhotoModal, setAttemptPhotoModal] = useState({open: false, visitIndex: 0});
    previousStatus.unshift({nextStatus: 1,previousStatus: 1,creationDate:packageData.creationDate});
    previousStatusIds.unshift(1);
    packageData.logChangeStatus
        .filter(log => log.previusStatus !== log.nextStatus)
        .forEach((log, index) => {
            if (log.nextStatus !== packageData.status) {
                if (index < indexOfCurrentStatusInLogs) {
                    if (!previousStatusIds.includes(log.nextStatus)) {
                        previousStatus.push(log);
                        previousStatusIds.push(log.nextStatus);
                    }
                } else {
                    if (!nextStatusIds.includes(log.nextStatus)) {
                        nextStatus.push(log);
                        nextStatusIds.push(log.nextStatus);
                    }
                }
            } else {
                indexOfCurrentStatusInLogs = index;
            }
        });

    // FIXME: Create a separate component
    const renderAttemptMenu = (status) => {
        if ([31, 32].includes(parseInt(status))) {
            const visitIndex = status === 31 ? 0 : 1;
            return (
                <div className="paquer-state__options">
                    <a className="margin-left-5" onClick={() => {
                        setModalIndex(visitIndex);
                        setAttemptPhotoModal({open: true, visitIndex})}
                    }>Ver motivo</a>
                </div>
            );
        }
        return '';
    }
        
    return (
        <div className="status-card">
            <ul className="status-card__list">
                <Modal
                    className="Modal"
                    overlayClassName="Overlay"
                    isOpen={attemptPhotoModal.open && modalIndex === attemptPhotoModal.visitIndex}
                    onRequestClose={() => setAttemptPhotoModal({open: false, visitIndex: 0})}
                    ariaHideApp={false}
                >
                    <p className="description">
                        <span className="title">Descripción: </span>
                        {packageData.shippingScheduleDestination.visits[attemptPhotoModal.visitIndex]
                            ? packageData.shippingScheduleDestination.visits[attemptPhotoModal.visitIndex].reason
                            : ''}
                    </p>
                    { packageData.shippingScheduleDestination.visits[attemptPhotoModal.visitIndex]
                        && packageData.shippingScheduleDestination.visits[attemptPhotoModal.visitIndex].photo?
                        <img
                            className="attempt-photo"
                            src={packageData.shippingScheduleDestination.visits[attemptPhotoModal.visitIndex].photo}
                        />
                        : ''
                    }
                </Modal>
                {previousStatus.map((log, index) => (
                    <PastStatus
                        key={index}
                        log={log}
                        statusList={statusList}
                        renderAttemptMenu={renderAttemptMenu}
                    />
                ))}
                <CurrentStatus
                    status={packageData.status}
                    statusList={statusList}
                    paquer={packageData.shippingScheduleDestination.driver}
                    renderAttemptMenu={renderAttemptMenu}
                />
                {nextStatus.map((log, index) => (
                    <NextStatus key={index} log={log} statusList={statusList} />
                ))}
                
            </ul>
        </div>
    );
};

export default StatusCards;
