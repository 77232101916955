import React from 'react';
import './ErrorHandler.css';

const ErrorHandler = () => (
    <div className="error-handler">
        <h1 className="error-handler__title">
            No se pudo establecer conexión con nuestros servidores.
        </h1>
    </div>
);

export default ErrorHandler;
