import React from 'react';
import PropTypes from 'prop-types';
import '../login/Login.scss';
import './Register.scss';
import Header from '../common/Header';

class RegisterSuccess extends React.Component {
    render() {
        return (
            <form
                className="success container login"
            >
                <Header />
                <h2>{this.props.message ? this.props.message : 'Registro exitoso!'} </h2>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        this.props.history.push(`/login`)
                    }}
                    className="formButton"
                >
                    Volver
                </button>
            </form>
        );
    }
}

RegisterSuccess.propTypes = {
    history: PropTypes.object
};

export default RegisterSuccess;
