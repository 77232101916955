import React from 'react';
import PropTypes from 'prop-types';
import '../../login/Login.scss';
import '../Register.scss';
import { sendRecoveryCode } from '../../../api/paqueryBackend';
import Header from '../../common/Header';
import RecoveryPasswordForm from "./RecoveryPasswordForm";

class RecoveryPassword extends React.Component {
    state = {};

    onSendRecoveryCode = async (
        email
    ) => {
        try {
            await sendRecoveryCode(email);
            this.props.history.push(`/changePassword`);
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    render() {
        return (
            <div className="register login">
                <Header />
                <h2> Recuperar Contraseña </h2>
                <br/>
                {this.state.error ? (
                    <div className="errors">{this.state.error}</div>
                ) : (
                    ''
                )}

                <RecoveryPasswordForm
                    onSendRecoveryCode={this.onSendRecoveryCode.bind(this)}
                />
            </div>
        );
    }
}

RecoveryPassword.propTypes = {
    history: PropTypes.object
};

export default RecoveryPassword;
