import React from 'react';
import './StatusBar.css';

const StatusBar = ({ history }) => (
    <section className="delivery-status-bar">
        <div className="delivery-status-bar-back">
            <a className="back" onClick={() => history.goBack()}>
                {' '}
                &lsaquo; &nbsp; Volver
            </a>
        </div>
        {/* <div className="delivery-status-bar-timer">
            Tu paquete llega en 10:40:22
        </div>
        <div className="delivery-status-bar-buttons">
            <a href="#" className="underlined">
                reprogramar horario
            </a>
            <a href="#" className="underlined">
                editar destino
            </a>
        </div> */}
    </section>
);

export default StatusBar;
