import React, { useState } from 'react'
import './HistoryItem.css';
import { IoMdMore } from 'react-icons/io';
import { FaFileSignature } from 'react-icons/fa';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';

const HistoryItem = ({ historyItem, statusList, sizes, deliveryTermList }) => {
    const size = sizes.find(size => historyItem.packageSize === size.value);
    const deliveryTerm = deliveryTermList.find(dtl => historyItem.deliveryTerm === dtl.value);
    const status = statusList.find(
        status => historyItem.status === status.value
    );
    const {
        shippingScheduleDestination,
        externalCode,
        caption,
        signatureImage
    } = historyItem;
    let paquer = '';
    let paquerImage = '/images/user-logo.png';
    let docNumber = '';
    
    if (shippingScheduleDestination && shippingScheduleDestination.driver) {
        paquer = `${shippingScheduleDestination.driver.name} ${shippingScheduleDestination.driver.lastName}`;
        if (shippingScheduleDestination.driver.avatar) {
            paquerImage = shippingScheduleDestination.driver.avatar;
        }
        if (shippingScheduleDestination.driver.docNumber) {
            docNumber = shippingScheduleDestination.driver.docNumber;
        }
    }
    
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modalFirm, setModalFirm] = useState(false);
    const toggleFirm = () => setModalFirm(!modalFirm)

    return (

        <li className="history-item">
            <div className="number col-lg-1 col-md-1 col-sm-12 col-xs-12">
                <h4>{externalCode}</h4>
            </div>
            <div className="address col-lg-3 col-md-3 col-sm-12 col-xs-12">
                <h4>
                    {shippingScheduleDestination.shippingAddress.addressDetail}
                </h4>
            </div>

            <div className="content col-lg-1 col-md-1 col-sm-12 col-xs-12" >
                <h4>{deliveryTerm.description}</h4>
            </div>

            <div className="term col-lg-1 col-md-1 col-sm-12 col-xs-12">
                <h4>{caption}</h4>
            </div>

            <div className="size col-lg-1 col-md-1 col-sm-12 col-xs-12" style = {{textAlign:'center'}}>
                <span>{size ? size.name[0] : ''}</span>
            </div>
            <div className="img-paquer col-lg-1 col-md-1 col-sm-12 col-xs-12">   
                <img
                    style={{
                        borderRadius: '50%',
                        height: '30px',
                        cursor:'pointer'
                    }}
                    src={paquerImage}
                    alt="user"
                    title={paquer}
                    onClick={toggle}
                />
            </div>
            <div className="receiver col-lg-1 col-md-1 col-sm-12 col-xs-12">
                <h4>{shippingScheduleDestination.name}</h4>
            </div>
            <div className="sign col-lg-1 col-md-1 col-sm-12 col-xs-12" >
                {signatureImage !== null 
                    ? 
                <img src = {signatureImage} 
                alt = "Firm"
                style = {{cursor:'pointer'}}
                onClick={toggleFirm}
                 /> 
                    : 
                <FaFileSignature 
                color={'#9c9c9c'} 
                size={16} />}
            </div>
            <div className="delivered col-lg-2 col-md-2 col-sm-12 col-xs-12"> 
                <span>{status ? status.name : ''}</span>
                <i>
                    <IoMdMore color={'#254370'} size={16} />
                </i>
            </div>

            <Modal isOpen={modal} toggle={toggle} >
                <ModalHeader className = "backCool">
                    <h2 className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        {paquer}
                    </h2>
                </ModalHeader>
                <ModalBody >
                    <div className = "row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img className = "BigImg" src = {paquerImage} alt = "Avatar" title = "Avatar User"/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className = "backCool">
                    <Button outline block color="white" onClick={toggle}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalFirm} toggle={toggleFirm} >
                <ModalHeader className = "backCool">
                    <h2 className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                        {`${shippingScheduleDestination.name} - ${docNumber}`}
                    </h2>
                </ModalHeader>
                <ModalBody >
                    <div className = "row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img className = "BigImg" src = {signatureImage} alt = "Firm" title = "Firm User" />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className = "backCool">
                    <Button outline block color="white" onClick={toggleFirm}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            </li>
    );
};     

export default HistoryItem;