import React from 'react';
import Navbar from '../common/navbar/Navbar';
import StatusBar from '../common/statusBar/StatusBar';
import './OrderDetail.css';
import OrderWrapper from './orderWrapper/OrderWrapper';

const OrderDetail = ({ history }) => {
    return (
        <div className="order-detail">
            <Navbar history={history} />
            <StatusBar history={history} />
            <OrderWrapper />
        </div>
    );
};

export default OrderDetail;
