/* eslint-disable no-console */
import axios from 'axios';
import getConfig from '../config/getConfig';
//import getConfig from '../config/getConfig.js';

const axiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Accept: 'application/json'
    }
});
const basicPackageService = getConfig('basicPackage');

const axiosInstancePackageService = axios.create({
    baseURL: `${process.env.REACT_APP_MS_API_URL}`,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
    }
});

export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post(
            '/apptoken',
            `grant_type=password&username=${email}&password=${password}`
        );
        return response.data;
    } catch (error) {
        // console.log('Login error: ', error);
        throw new Error(error.response.data.error_description);
    }
};

export const register = async (
    Name,
    LastName,
    Email,
    Pwd,
    Mobile,
    CityName
) => {
    try {
        const response = await axiosInstance.post(
            '/api/customer/create',
            {
                Name,
                LastName,
                Email,
                Pwd,
                Mobile,
                CityName
            },
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        );
        return response.data;
    } catch (error) {
        // console.log('Register error: ', error.response);
        throw new Error(error.response.data.message);
    }
};

export const getLastOrders = async authToken => {
    const token = `Bearer ${authToken}`;
    try {
        // don't know their API... so will get all of the packages fetching with 1000000.
        const response = await axiosInstance.get(
            '/api/package/getpage/?page=0&take=1000000&isScheduled=true&desc=true&search=&sortColumn=&searchRolled=',
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response.data.data;
    } catch (error) {
        // console.log('Login error: ', error);
        throw new Error(error.response.data.error_description);
    }
};

export const getUserData = async authToken => {
    const token = `Bearer ${authToken}`;
    try {
        const response = await axiosInstance.get('/api/profile/load', {
            headers: {
                Authorization: token
            }
        });
        return response.data.data;
    } catch (error) {
        console.log('Login error: ', error);
        //throw new Error(error.response.data.error_description);
    }
};

export const getPackageStatus = async () => {
    try {
        const response = await axiosInstancePackageService.get(
            '/parametrics/packageStatus'
        );
        return response.data.data;
    } catch (error) {
        console.log('Login error: ', error);
        //throw new Error(error);
    }
};

export const getPackageSizes = async () => {
    try {
        const response = await axiosInstancePackageService.get(
            '/parametrics/packageSize'
        );
        return response.data.data;
    } catch (error) {
        console.log('Login error: ', error);
        // throw new Error(error.response.data.error_description);
    }
};

export const getHistory = async authToken => {
    const token = `Bearer ${authToken}`;
    try {
        const response = await axiosInstance.get(
            '/api/package/gethistory/?page=0&take=1000000&desc=true&search=&sortColumn=',
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response.data.data;
    } catch (error) {
        // console.log('Login error: ', error);
        //throw new Error(error.response.data.error_description);
    }
};

export const DeliveryTerms = [
    { description: 'Dos días hábiles', value: 1 },
    { description: 'Día siguiente', value: 2 },
    { description: 'Mismo día', value: 3 },
    { description: 'Express', value: 4 },
    { description: '1 Semana despúes', value: 5 }
];

export const getDeliveryTermList = async () => {
    return await Promise.resolve(DeliveryTerms);
};

export const getPackageDataById = async (authToken, packageId) => {
    const token = `Bearer ${authToken}`;
    try {
        const response = await axiosInstance.get(
            `/api/package/load?id=${packageId}`,
            {
                headers: {
                    Authorization: token
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.log('Login error: ', error);
        //throw new Error(error.response.data.error_description);
    }
};

export const sendRecoveryCode = async email => {
    try {
        const response = await axiosInstance.get(
            `api/customeradmin/sendrecoverypassword?Email=${email}`,
            {
                headers: {}
            }
        );
        return response.data.data;
    } catch (error) {
        console.log('sendRecoveryCode error: ', error.response.data.message);
    }
};

export const changePassword = async (Code, NewPassword) => {
    try {
        const response = await axiosInstance.post(
            `api/customeradmin/recoverypassword`,
            {
                Code,
                NewPassword
            },
            {
                headers: {
                    'Content-type': 'application/json'
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.log('sendRecoveryCode error: ', error.response.data.message);
    }
};

export const loadUserPreRegister = async registerCode => {
    try {
        const response = await axiosInstance.get(
            `api/customer/getbasicinfo?code=${registerCode}`,
            {
                headers: {}
            }
        );
        return response.data.data;
    } catch (error) {
        console.log('loadUserPreRegister error: ', error.response.data.message);
    }
};

export const getPackageDataByExternalCode = async (authToken, externalCode) => {
    const token = `Bearer ${authToken}`;
    try {
        let encodeExternalCode = encodeURIComponent(externalCode);
        const response = await axiosInstancePackageService.get(
            `/admin/trackByExternalCode?externalCode=${encodeExternalCode}`,
            {
                headers: {
                    Authorization: basicPackageService
                }
            }
        );
        return response.data.data;
    } catch (error) {
        console.log('Login error: ', error);
        //throw new Error(error.response.data.error_description);
    }
};

export const getCountryDataByCityId = async (authToken, cityId) => {
    const token = `Bearer ${authToken}`;
    try {
        const response = await axiosInstance.get(
            `/api/geolocation/loadcities/${cityId}/country`
        );
        return response.data;
    } catch (error) {
        console.log('Login error: ', error);
        //throw new Error(error.response.data.error_description);
    }
};
