/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import {
    getPackageStatus,
    getPackageSizes,
    getPackageDataById,
    getPackageDataByExternalCode,
    getCountryDataByCityId
} from '../paqueryBackend';

export const getOrders = async cookies => {
    const status = await getPackageStatus();
    const sizes = await getPackageSizes();
    const urlParams = new URLSearchParams(window.location.search);
    const hash = window.location.hash;
    const packageId = urlParams.get('packageId');
    const externalCode = urlParams.get('externalCode');
    let results = {};
    let showData = false;
    if (packageId) {
        if (cookies.token) {
            try {
                results = await getPackageDataById(cookies.token, packageId);
                if (results) showData = true;
            } catch (error) {
                console.log(error);
            }
        }
    } else if (externalCode) {
        try {
            const response = await getPackageDataByExternalCode(
                cookies.token,
                externalCode
            );
            const emptyAnswer = response.length === 0;
            if (!emptyAnswer) {
                results = response[0];
                 var sortedStatus =   results.logStatusPackages.slice().sort(function (a, b) {
                                          return new Date(a.creationDate) - new Date(b.creationDate);
                                        });
                 results.logChangeStatus = [];
                 results.logChangeStatus =sortedStatus;
            }
            showData = !emptyAnswer;
        } catch (error) {
            console.log(error);
        }
    } else if (hash) {
        try {
            const response = await getPackageDataByExternalCode(
                cookies.token,
                hash
            );
            const emptyAnswer = response.length === 0;
            if (!emptyAnswer) {
                results = response[0];
            }
            showData = !emptyAnswer;
        } catch (error) {
            console.log(error);
        }
    }

    if (results != null 
        && results.shippingScheduleDestination != null 
        && results.shippingScheduleDestination.driver != null
    ) {
        const response = await getCountryDataByCityId(
            cookies.token,
            results.shippingScheduleDestination.driver.cityId
        );

        if (response && response.country) {
            results.shippingScheduleDestination.driver.country = response.country;
        }
    }

    return [status, sizes, results, showData];
};
