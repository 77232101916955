import React from 'react';
import { renderToString } from 'react-dom/server';
import PaquerCard from '../paquerCard/PaquerCard';

// Define a callback function to process the routing response:
export const onRouteResult = (
    result,
    map,
    ui,
    paquer,
    originLocation,
    previousBounds
) => {
    var route, routeShape, startPoint, endPoint, linestring;
    if (result.response && result.response.route) {
        // Pick the first route from the response:
        route = result.response.route[0];
        // Pick the route's shape:
        routeShape = route.shape;

        // Create a linestring to use as a point source for the route line
        linestring = new window.H.geo.LineString();

        // Push all the points in the shape into the linestring:
        routeShape.forEach(function(point) {
            var parts = point.split(',');
            linestring.pushLatLngAlt(parts[0], parts[1]);
        });

        // Retrieve the mapped positions of the requested waypoints:
        startPoint = route.waypoint[0].mappedPosition;
        endPoint = route.waypoint[1].mappedPosition;

        // Create a polyline to display the route:
        var routeLine = new window.H.map.Polyline(linestring, {
            style: { strokeColor: '#27c1b9', lineWidth: 3 }
        });

        const startIcon = new window.H.map.DomIcon(
            `
                <div class="start-marker">
                    <div class="paquer-ellipsis"> </div>
                    <div class="paquer-ellipsis-2"> </div>
                    <div class="image-container">
                        <img
                            src="${paquer.avatar || '/images/user-logo.png'}"
                            alt="paquer-small"
                            class="paquer-image"
                        />
                    </div>
                </div>
            `,
            {
                // the function is called every time marker enters the viewport
                onAttach: function(clonedElement) {
                    const showBubble = () => {
                        if (paquer) {
                            // eslint-disable-next-line no-console
                            console.log(paquer);
                            var bubble = new window.H.ui.InfoBubble(
                                {
                                    lat: startPoint.latitude,
                                    lng: startPoint.longitude
                                },
                                {
                                    content: renderToString(
                                        <PaquerCard paquer={paquer} />
                                    )
                                }
                            );
                            // show info bubble
                            ui.addBubble(bubble);
                        }
                    };
                    // computer
                    clonedElement.addEventListener('click', showBubble);
                    // mobile
                    clonedElement.addEventListener('touchstart', showBubble);
                }
            }
        );

        const endIcon = new window.H.map.DomIcon(`
            <img
                src='/images/destiny.png'
                alt="destiny"
                class="destiny-image"
            />
        `);

        const originIcon = new window.H.map.DomIcon(`
            <img
                src='/images/short-logo.png'
                alt="destiny"
                class="origin-image"
            />
        `);

        // Create a marker for the start point:
        var startMarker = new window.H.map.DomMarker(
            {
                lat: startPoint.latitude,
                lng: startPoint.longitude
            },
            {
                icon: startIcon
            }
        );

        // Create a marker for the end point:
        var endMarker = new window.H.map.DomMarker(
            {
                lat: endPoint.latitude,
                lng: endPoint.longitude
            },
            {
                icon: endIcon
            }
        );

        // Create a marker for the origin point:
        var originMarker = new window.H.map.DomMarker(originLocation, {
            icon: originIcon
        });

        map.removeObjects(map.getObjects());
        // Add the route polyline and the two markers to the map:
        map.addObjects([routeLine, startMarker, endMarker, originMarker]);

        var bounds = routeLine.getBounds();
        const boundsHaveChanged =
            Object.keys(previousBounds).length === 0 ||
            previousBounds.ga !== bounds.ga ||
            previousBounds.ha !== bounds.ha ||
            previousBounds.ka !== bounds.ka ||
            previousBounds.ja !== bounds.ja;

        if (boundsHaveChanged) {
            // Set the map's viewport to make the whole route visible:
            map.setViewBounds(bounds)
        }
        return bounds;
    }
};
