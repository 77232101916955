import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import UserDropdown from '../../dashboard/userDropdown/UserDropdown';
import './navbar.css';
import { IoIosNotifications } from 'react-icons/io';
import { getUserData } from '../../../api/paqueryBackend';
import AccountWrapper from '../accountWrapper/AccountWrapper';

const Navbar = ({ history }) => {
    const [cookies] = useCookies(['token']);
    const [userName, setUserName] = useState('');

    useEffect(() => {
        const getUser = async () => {
            if (cookies.token) {
                const user = await getUserData(cookies.token);
                if (user) {
                    setUserName(`${user.name} ${user.lastName}`);
                }
            }
        };
        getUser();
    }, []);

    if (!cookies.token) {
        return (
            <nav className="navbar">
                <div className="logo-wrapper">
                    {' '}
                    <img
                        src="/images/logo.png"
                        alt="PaQuery logo"
                        onClick={() => history.push('/')}
                    />
                    <div className="navbar-logo-text">
                        Ecommerce
                        <br /> Delivery Partner
                    </div>
                </div>

                <AccountWrapper history={history} />
            </nav>
        );
    }

    return (
        <nav className="navbar">
            <div className="navbar-logo">
                <img
                    src="/images/logo.png"
                    alt="paquery logo"
                    onClick={() => history.push('/')}
                />
                <div className="navbar-logo-text">
                    Ecommerce
                    <br /> Delivery Partner
                </div>
            </div>
            <div className="navbar-userArea">
                <div className="navbar-userArea-notifications">
                    {' '}
                    {/* <span>13</span> */}
                    <i className="navbar-userArea-notifications-icon">
                        <IoIosNotifications color={'#fafafa'} size={35} />
                    </i>
                </div>

                <UserDropdown username={userName} history={history} />
            </div>
        </nav>
    );
};
export default Navbar;
