import React from 'react';
import PropTypes from 'prop-types';
import '../../login/Login.scss';


class RecoveryPasswordForm extends React.Component {
    state = {
        email: ''
    };

    onInputChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = async event => {
        event.preventDefault();
            this.props.onSendRecoveryCode(
                this.state.email
            );
    };

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <div className="">
                    <div className="input-group email">
                        <label htmlFor="email">Correo electrónico *</label>
                        <input
                            id="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.onInputChange}
                            placeholder={"ingrese su email"}
                            required
                        />
                    </div>
                    <button type="submit" className="formButton">
                        Enviar Email
                    </button>
                </div>
            </form>
        );
    }
}

RecoveryPasswordForm.propTypes = {
    onRegisterSubmit: PropTypes.func
};

export default RecoveryPasswordForm;
