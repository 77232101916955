import React from 'react';
import './PaquerCard.css';
import { FaWhatsappSquare } from 'react-icons/fa';
import { isMobileOrTablet } from '../../../utils/isMobile';
import CloseIcon from '@material-ui/icons/Close';

const getWhatsappUrl = paquer => {
    let mobile = getMobile(paquer);
    if (isMobileOrTablet()) {
        return `https://api.whatsapp.com/send?phone=${mobile}`;
    }
    return `https://web.whatsapp.com/send?phone=${mobile}`;
};

const getMobile = paquer => {
    if (paquer && paquer.country && paquer.country.dialingCode && !paquer.mobile.startsWith(paquer.country.dialingCode)) {
        if (!paquer.mobile.startsWith('+')) {
            let dialingCodeWithoutPlus = paquer.country.dialingCode.replace('+', '');
            if (paquer.mobile.startsWith(dialingCodeWithoutPlus)) {
                return '+' + paquer.mobile;
            }
            return paquer.country.dialingCode + paquer.mobile;
        }
    }
    return paquer.mobile;
};

const PaquerCard = ({ paquer, close }) => (
    <div className="paquer-card">
        <h2 className="title-wrapper-top__heading">
            {close ? (
                <div className="closeIcon">
                    <CloseIcon onClick={close} />
                </div>
            ) : (
                ''
            )}
        </h2>
        <div className="paquer-card__img-wrapper">
            <img
                src={paquer.avatar || '/images/user-logo.png'}
                alt="paquer-big"
                className="img-wrapper__picture"
            />
            <div className="paquer-card__title-wrapper">
                {' '}
                <h3 className="img-wrapper__title">Nombre</h3>
                <h5 className="img-wrapper__subtitle">{`${paquer.name} ${paquer.lastName}`}</h5>
                <h3 className="img-wrapper__title">Paquer Id</h3>
                <h5 className="img-wrapper__subtitle">{paquer.code}</h5>
                {/* <h3 className="img-wrapper__title">Paquer desde hace</h3>
                <h5 className="img-wrapper__subtitle">2 meses</h5>
                <h3 className="img-wrapper__title">Paquetes entregados</h3>
                <h5 className="img-wrapper__subtitle">500</h5> */}
                <h3 className="img-wrapper__title">Calificación</h3>
                <h5 className="img-wrapper__subtitle">{paquer.rating}</h5>
            </div>
        </div>
        <div className="paquer-card__contact">
            <div className="contact-wrapper">
                {' '}
                <FaWhatsappSquare
                    style={{ color: '#1cfe73' }}
                    className="whatsappIcon"
                />
                {paquer.mobile ? (
                    <a
                        href={getWhatsappUrl(paquer)}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="contact-wrapper__link"
                    >
                        {' '}
                        {`Contactar a ${paquer.name} ${paquer.lastName}`}
                    </a>
                ) : (
                    <a className="contact-wrapper__link disabled">
                        {`Contactar a ${paquer.name} ${paquer.lastName}`}
                    </a>
                )}
            </div>
        </div>
    </div>
);

export default PaquerCard;
