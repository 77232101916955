/* eslint-disable no-console */
/* global process */
import React, { useState, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import Navbar from '../common/navbar/Navbar';
import StatusBar from '../common/statusBar/StatusBar';
import ErrorHandler from '../common/errorHandler/ErrorHandler';
import './mapDisplay.css';
import { onRouteResult } from './routeDrawer/routeDrawer';
import { getOrders } from '../../api/utils/getOrder';
import getConfig from '../../config/getConfig';

const MapDisplay = ({ history }) => {
    const [mapData, setMapData] = useState({});
    const [cookies] = useCookies(['token']);
    const [error, setError] = useState(null);

    const saveField = (field, value) => {
        setMapData({
            ...mapData,
            [field]: value
        });
    };

    const renderMap = async () => {
        try {
            const platform = new window.H.service.Platform({
                apikey: getConfig('HERE_API_KEY'),
                useHTTPS: true
            });
            saveField('platform', platform);
            const pixelRatio = window.devicePixelRatio || 1;
            saveField('pixelRatio', pixelRatio);
            const layer = platform.createDefaultLayers({
                tileSize: pixelRatio === 1 ? 256 : 512,
                ppi: pixelRatio === 1 ? undefined : 320
            });
            const container = document.getElementById('custom-here-map');

            const [, , results] = await getOrders(cookies);
            const destinyLocation = results.shippingScheduleDestination
                ? {
                      lat:
                          results.shippingScheduleDestination.shippingAddress
                              .lat,
                      lng:
                          results.shippingScheduleDestination.shippingAddress
                              .lng
                  }
                : null;

            const map = new window.H.Map(container, layer.normal.map, {
                zoom: 15,
                center: destinyLocation
                    ? destinyLocation
                    : { lat: -34.6037, lng: -58.3816 },
                pixelRatio: pixelRatio,
                gestureHandling: 'auto'
            });

            const events = new window.H.mapevents.MapEvents(map);
            const behavior = new window.H.mapevents.Behavior(events);
            saveField('behavior', behavior);
            const ui = new window.H.ui.UI.createDefault(map, layer, 'es-ES');
            ui.getControl('zoom').setDisabled(false);
            saveField('ui', ui);

            window.addEventListener('resize', () => map.getViewPort().resize());

            // Get an instance of the routing service:
            var router = platform.getRoutingService();

            let bounds = {};

            if (!results.geolocation || !results.shippingScheduleOrigin) return;

            setInterval(function() {
                const originLocation = {
                    lat: results.shippingScheduleOrigin.shippingAddress.lat,
                    lng: results.shippingScheduleOrigin.shippingAddress.lng
                };
                getOrders(cookies).then(([, , results]) => {
                    const paquerLocation = {
                        lat: results.geolocation.lat,
                        lng: results.geolocation.lng
                    };
                    const paquer = results.shippingScheduleDestination
                        ? results.shippingScheduleDestination.driver
                        : null;
                    // Create the parameters for the routing request:
                    var routingParameters = {
                        // The routing mode:
                        mode: 'fastest;car',
                        // The start point of the route:
                        waypoint0: `geo!${paquerLocation.lat},${paquerLocation.lng}`,
                        // The end point of the route:
                        waypoint1: `geo!${destinyLocation.lat},${destinyLocation.lng}`,
                        // To retrieve the shape of the route we choose the route
                        // representation mode 'display'
                        representation: 'display',
                        app_id: getConfig('HERE_API_ID'),
                        app_code: getConfig('HERE_API_CODE')
                    };

                    // Call calculateRoute() with the routing parameters,
                    // the callback and an error callback function (called if a
                    // communication error occurs):
                    const saveResult = result => {
                        bounds = onRouteResult(
                            result,
                            map,
                            ui,
                            paquer,
                            originLocation,
                            bounds
                        );
                    };
                    router.calculateRoute(
                        routingParameters,
                        result => saveResult(result),
                        error => console.log(error)
                    );
                });
            }, 2000);
        } catch (error) {
            console.log(error);
            setError(error);
        }
    };

    useEffect(() => {
        renderMap();
    }, []);

    return (
        <div className="mapdisplay">
            <Navbar history={history} />
            <StatusBar history={history} />
            {error && <ErrorHandler />}
            {!error && (
                <div className="map-display__container" id="custom-here-map" />
            )}
        </div>
    );
};

export default MapDisplay;
