import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import './UserDropdown.css';

const UserDropdown = ({ username, history }) => {
    const [, , removeCookie] = useCookies(['token']);
    const [showDropdown, setShowDropdown] = useState(false);

    const onClick = async () => {
        await removeCookie('token', { path: '/' });
        history.push(`/`);
    };

    return (
        <div className="dropdown">
            <div className="user">
                <img
                    style={{
                        borderRadius: '50%',
                        marginRight: '15px'
                    }}
                    src="/images/user-logo.png"
                    alt="user"
                />
                <button onClick={() => setShowDropdown(!showDropdown)}>
                    {username} &#9660;
                </button>
                <ul className={showDropdown ? 'visible' : ''}>
                    <li onClick={onClick}>
                        <p> Cerrar sesión </p>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default UserDropdown;
