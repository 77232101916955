import React, { useState, useRef } from 'react';
import Chevron from './Chevron';
import { FaFileSignature } from 'react-icons/fa';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import './Accordion.css';

function Accordion({
    historyItem,
    statusList,
    sizes,
}) {
    const size = sizes.find(size => historyItem.packageSize === size.value);
    const status = statusList.find(status => historyItem.status === status.value);
    let paquer = '';
    let paquerImage = '/images/user-logo.png';
    let docNumber = '';
    
    if (historyItem.shippingScheduleDestination && historyItem.shippingScheduleDestination.driver) {
        paquer = `${historyItem.shippingScheduleDestination.driver.name} ${historyItem.shippingScheduleDestination.driver.lastName}`;
        if (historyItem.shippingScheduleDestination.driver.avatar) {
            paquerImage = historyItem.shippingScheduleDestination.driver.avatar;
        }
        if (historyItem.shippingScheduleDestination.driver.docNumber) {
            docNumber = historyItem.shippingScheduleDestination.driver.docNumber;
        }
    }

    const [setActive, setActiveState] = useState('');
    const [setHeight, setHeightState] = useState('0px');
    const [setRotate, setRotateState] = useState('accordion__icon');

    const content = useRef(null);

    function toggleAccordion() {
        setActiveState(setActive === '' ? 'active' : '');
        setHeightState(
            setActive === 'active' ? '0px' : `${content.current.scrollHeight}px`
        );
        setRotateState(
            setActive === 'active'
                ? 'accordion__icon'
                : 'accordion__icon rotate'
        );
    }
              
    const [modal, setModal] = useState(false);
    const toggle = () => setModal(!modal);
    const [modalFirm, setModalFirm] = useState(false);
    const toggleFirm = () => setModalFirm(!modalFirm)


    return (
        <div className="accordion__section">
            <button
                className={`accordion ${setActive}`}
                onClick={toggleAccordion}
            >
                <h5 className="accordion-label">
                    PaQuery ID: {historyItem.externalCode}
                </h5>
                <h5 className="accordion-label">
                    Contenido: {historyItem.caption}
                </h5>
                <h5 className="accordion-label received">
                    {status ? status.name : ''}
                </h5>

                <Chevron className={`${setRotate}`} width={10} fill={'#777'} />
            </button>
            <div
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
                className="accordion__content"
            >
                <div className="accordion__text">
                    <h5 className="accordion-label">
                        Destinatario:{' '}
                        {`${historyItem.user.name} ${historyItem.user.lastName}`}
                    </h5>
                    <h5 className="accordion-label">
                        Destino:{' '}
                        {
                            historyItem.shippingScheduleDestination
                                .shippingAddress.addressDetail
                        }
                    </h5>
                    <h5 className="accordion-label">{`Plazo: ${historyItem.deliveryTerm}hs`}</h5>
                    <h5 className="accordion-label">
                        Tamaño: {size ? size.name : ''}
                    </h5>
                    <div className="accordion-inline">
                        <h5 className="accordion-label">Paquer: </h5>
                        <img
                            style={{
                                borderRadius: '50%',
                                height: '30px',
                                cursor:'pointer'
                            }}
                            src={paquerImage}
                            alt="user"
                            title={historyItem.paquer}
                            onClick={toggle}
                        />
                    </div>
                    <h5 className="accordion-label">
                        Recibio: {historyItem.shippingScheduleDestination.name}
                    </h5>
                    <div className="accordion-inline">
                        <h5 className="accordion-label">Firma: </h5>
                        {historyItem.signatureImage !== null 
                            ? 
                        <img src = {historyItem.signatureImage} 
                        alt = "Firm"
                        style = {{cursor:'pointer'}}
                        onClick={toggleFirm}
                        /> 
                            : 
                        <FaFileSignature 
                        color={'#9c9c9c'} 
                        size={16} />}
                    </div>
                </div>
            </div>


            <Modal isOpen={modal} toggle={toggle} class = " modal-content bg-light" >
                <ModalHeader className = "backCool">
                    <h2 className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">                       
                        {paquer}
                    </h2>
                </ModalHeader>
                <ModalBody >
                    <div className = "row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img className = "BigImg" src = {
                                historyItem.shippingScheduleDestination.driver &&
                                typeof(historyItem.shippingScheduleDestination.driver.avatar) === 'string' ?
                                    historyItem.shippingScheduleDestination.driver.avatar :
                                    '/images/user-logo.png'
                                } alt = "Avatar" title = "Avatar User"/>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className = "backCool">
                    <Button outline block color="white" onClick={toggle}>Cerrar</Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={modalFirm} toggle={toggleFirm} size = "sm">
                <ModalHeader className = "backCool">
                    <h2 className="col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">                       
                        {`${historyItem.shippingScheduleDestination.name} - ${docNumber}`}
                    </h2>
                </ModalHeader>
                <ModalBody >
                    <div className = "row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <img className = "BigImg" src = {historyItem.signatureImage} alt = "Firm" title = "Firm User" />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter className = "backCool">
                    <Button outline block color="white" onClick={toggleFirm}>Cerrar</Button>
                </ModalFooter>
            </Modal>


        </div>
    );
}

export default Accordion;
