import React from 'react';
import moment from 'moment';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import LogRocket from 'logrocket';
import { createBrowserHistory } from 'history';
import Query from './query/Query';
import './Global.scss';
import ErrorHandler from './common/errorHandler/ErrorHandler';
import Login from './login/Login';
import Dashboard from './dashboard/Dashboard';
import OrderDetail from './orderDetail/OrderDetail';
import { useCookies } from 'react-cookie';
import Register from './register/Register';
import RecoveryPassword from './register/recoveryPassword/RecoveryPassword';
import ChangePassword from './register/changePassword/ChangePassword';
import MapDisplay from './mapDisplay/mapDisplay';

// Sets the language of moment
require('moment/min/locales.min');
moment.locale('es');

LogRocket.init('fbpaty/paquery');

const App = () => {
    const [cookies] = useCookies(['token']);
    const needsAuthentication = (component, requiresAuth) => {
        if (
            (!cookies.token && requiresAuth) ||
            (cookies.token && !requiresAuth)
        ) {
            const RedirectRoute = () => <Redirect to="/" />;
            return RedirectRoute;
        }
        return component;
    };
    return (
        <div
            className="container-main"
            style={{ padding: '0', height: '100vh' }}
        >
            {/* The Router key allows to modify Route History */}
            <Router key={Math.random()} history={createBrowserHistory()}>
                <Switch>
                    <Route
                        path="/dashboard"
                        exact
                        component={needsAuthentication(Dashboard, true)}
                    />
                    <Route
                        path="/login"
                        exact
                        component={needsAuthentication(Login, false)}
                    />
                    <Route path="/order-detail" exact component={OrderDetail} />
                    <Route
                        path="/register"
                        exact
                        component={needsAuthentication(Register, false)}
                    />
                    <Route
                        path="/recoveryPassword"
                        exact
                        component={needsAuthentication(RecoveryPassword, false)}
                    />
                    <Route
                        path="/changePassword"
                        exact
                        component={needsAuthentication(ChangePassword, false)}
                    />
                    <Route path="/query" exact component={Query} />
                    <Route path="/mapdisplay" component={MapDisplay} />
                    <Redirect
                        path="/"
                        to={cookies.token ? '/dashboard' : '/login'}
                    />
                </Switch>
            </Router>
        </div>
    );
};

export default App;
