import React, { Component } from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import PropTypes from 'prop-types';
import './Login.scss';
import { login } from '../../api/paqueryBackend';
import { loginWithGoogle } from '../../api/googleLogin';
import ErrorHandler from '../common/errorHandler/ErrorHandler';
import { withCookies } from 'react-cookie';
import LoginForm from './LoginForm';
import Header from '../common/Header';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null
        };
    }

    saveToken = responseData => {
        const { cookies } = this.props;
        cookies.set('token', responseData.access_token, {
            maxAge: responseData.expires_in
        });
    };

    onLoginSubmit = async (email, password) => {
        try {
            const response = await login(email, password);
            this.saveToken(response);
            this.props.history.push(`/`);
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    onLoginGoogle = async data => {
        try {
            const response = await loginWithGoogle(data.tokenId);
            //this.saveToken(response);
            //this.props.history.push(`/`);
            // eslint-disable-next-line no-console
            console.log(response);
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    onLoginFacebook = data => {
        // eslint-disable-next-line no-console
        console.log(data);
    };

    render() {
        return (
            <div className="login">
                <Header />
                <h2 className="login__heading">
                    Para contactar con el PaQuer es necesario iniciar sesión
                </h2>
                {this.state.error ? (
                    <div className="errors">{this.state.error}</div>
                ) : (
                    ''
                )}


                <LoginForm onLoginSubmit={this.onLoginSubmit.bind(this)} />

                {/* <p>o</p>
        <div className="social-networks">
            <FacebookLogin
                appId={process.env.FACEBOOK_APP_ID}
                autoLoad={false}
                fields="name,email,picture"
                callback={this.onLoginFacebook}
                textButton="Ingresar con Facebook"
                cssClass="formButton"
            />
            <GoogleLogin
                clientId={process.env.GOOGLE_CLIENT_ID}
                buttonText="Ingresar con Google"
                onSuccess={this.onLoginGoogle}
                onFailure={this.onLoginGoogle}
                cookiePolicy={'single_host_origin'}
            />
        </div> */}
                <p className="no-margin">
                    ¿Todavía no tenés cuenta? {' '}
                    <a className="signup" href="/register">
                        Crea una
                    </a>
                </p>
                <p className="no-margin">
                    ¿Olvidaste la contraseña? {' '}
                    <a className="signup" href="/recoveryPassword">
                         Restablecer
                    </a>
                </p>
            </div>
        );
    }
}

Login.propTypes = {
    history: PropTypes.object,
    cookies: PropTypes.object
};

export default withCookies(Login);
