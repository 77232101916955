import React from 'react';
import moment from 'moment';
import './styles.css';
import { IoIosCheckmark } from 'react-icons/io';

const PastStatus = ({ log, statusList, renderAttemptMenu }) => {
    const status = statusList.find(
        status => log.nextStatus === status.value
    );
    return (
        <li className="status-card__list__element">
            <span className="status-card__list__item">
                <i className="status-card__list__item__icon">
                    <IoIosCheckmark
                        className="status-card__list__check"
                        size={22}
                    />
                </i>
            </span>{' '}
            <p className="status-card__list__item__paragraph past-status">
                {status ? status.name : ''} <br />
                {moment(log.creationDate).format('DD/MM/YYYY hh:mm')}
                {renderAttemptMenu(status.value)}
            </p>
        </li>
    );
};

export default PastStatus;
