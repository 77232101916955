import React from 'react';
import PropTypes from 'prop-types';
import '../../login/Login.scss';
import '../Register.scss';
import {changePassword} from '../../../api/paqueryBackend';
import ChangePasswordForm from './ChangePasswordForm';
import Header from '../../common/Header';
import RegisterSuccess from '../RegisterSuccess';

class ChangePassword extends React.Component {
    state = {};

    onChangePassword = async (
        code,
        password,
        confirmPassword
    ) => {
        if (password !== confirmPassword) {
            return this.setState({
                error: 'Las contraseñas deben coincidir'
            });
        }
        try {
            var changeIsTrue = await changePassword(code, password);

            if (changeIsTrue)
                this.props.history.push(`/changePassword?success=true`);
            else
                this.setState({error: "La contraseña no se pudo actualizar, verifica que el codigo sea el correcto"})
        } catch (error) {
            this.setState({error: error.message});
        }
    };

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('success') === 'true') {
            return <RegisterSuccess message={'Contraseña cambiada correctamente'} history={this.props.history}/>;
        }
        return (
            <div className="register login">
                <Header />
                <h2>Ingrese el código que recibió por email o SMS</h2>
                <br />
                {this.state.error ? (
                    <div className="errors">{this.state.error}</div>
                ) : (
                    ''
                )}

                <ChangePasswordForm
                    onChangePassword={this.onChangePassword.bind(this)}
                />
        </div>
        );
    }
}

ChangePasswordForm.propTypes = {
    history: PropTypes.object
};

export default ChangePassword;
