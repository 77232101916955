import React from 'react';
import PropTypes from 'prop-types';
import '../login/Login.scss';
import {loadUserPreRegister} from '../../api/paqueryBackend';

const zones = [
    {
        id: 1,
        name: 'Buenos Aires'
    },
    {
        id: 2,
        name: 'Santa Fe'
    }
];

const prefixes = [
    {
        id: 1,
        name: '+54'
    },
    {
        id: 2,
        name: '+59'
    }
];

class RegisterForm extends React.Component {
    state = {
        name: '',
        surname: '',
        email: '',
        password: '',
        confirmPassword: '',
        zone: '',
        phone: '',
        term: false,
        error: null,
        emailDisabled: false
    };

    componentWillMount() {
        this.verifyPreRegisterUser();
    }

    onInputChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = async event => {
        event.preventDefault();

        if (this.state.term) {
            this.props.onRegisterSubmit(
                this.state.name,
                this.state.surname,
                this.state.email,
                this.state.password,
                this.state.confirmPassword,
                this.state.phone,
                this.state.zone
            );
        }
    };

    verifyPreRegisterUser = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        var code = urlParams.get('code');
        if (code) {
            try {
                var userPreRegister = await loadUserPreRegister(code);
                this.setState({emailDisabled: true});
                this.setState({name: userPreRegister.name});
                this.setState({surname: userPreRegister.lastName});
                this.setState({email: userPreRegister.email});
                this.setState({phone: userPreRegister.mobile});
            } catch (error) {
                console.log('error ' + error);
            }
        }
    };

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <div className="">
                    <div className="input-group name">
                        <label htmlFor="name">Nombre *</label>
                        <input
                            id="name"
                            value={this.state.name}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group surname">
                        <label htmlFor="surname">Apellido *</label>
                        <input
                            id="surname"
                            type="surname"
                            value={this.state.surname}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group email">
                        <label htmlFor="email">Correo electrónico *</label>
                        <input
                            id="email"
                            type="email"
                            value={this.state.email}
                            disabled={this.state.emailDisabled}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group password">
                        <label htmlFor="password">Contraseña *</label>
                        <input
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group confirmPassword">
                        <label htmlFor="confirmPassword">
                            Confirmar contraseña *
                        </label>
                        <input
                            id="confirmPassword"
                            type="password"
                            value={this.state.confirmPassword}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group zone">
                        <label htmlFor="zone">Zona *</label>
                        <select
                            id="zone"
                            value={this.state.zone}
                            onChange={this.onInputChange}
                            required
                        >
                            {zones.map((zone, index) => (
                                <option key={index} value={zone.name}>
                                    {zone.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="input-group phone">
                        <label htmlFor="phone">Teléfono *</label>
                        <div className="phone-group">
                            <select
                                id="prefix"
                                value={this.state.prefix}
                                onChange={this.onInputChange}
                                required
                            >
                                {prefixes.map((prefix, index) => (
                                    <option key={index} value={prefix.id}>
                                        {prefix.name}
                                    </option>
                                ))}
                            </select>
                            <input
                                id="phone"
                                type="number"
                                value={this.state.phone}
                                onChange={this.onInputChange}
                                required
                            />
                        </div>
                    </div>
                    <div className="terms-group">
                        <input
                            type="checkbox"
                            name="terms"
                            value={this.state.term}
                            onChange={() =>
                                this.setState(prevState => ({
                                    term: !prevState.term
                                }))
                            }
                            required
                        />
                        <p>Acepto los terminos y condiciones</p>
                    </div>
                    <button type="submit" className="formButton">
                        Ingresar
                    </button>
                </div>
            </form>
        );
    }
}

RegisterForm.propTypes = {
    onRegisterSubmit: PropTypes.func
};

export default RegisterForm;
