import React from 'react';
import PropTypes from 'prop-types';
import '../login/Login.scss';
import './Register.scss';
import { register } from '../../api/paqueryBackend';
import RegisterForm from './RegisterForm';
import Header from '../common/Header';
import RegisterSuccess from './RegisterSuccess';

class Register extends React.Component {
    state = {};

    onRegisterSubmit = async (
        name,
        lastname,
        email,
        password,
        confirmPassword,
        mobile,
        cityname
    ) => {
        if (password !== confirmPassword) {
            return this.setState({
                error: 'Las contraseñas deben coincidir'
            });
        }
        try {
            await register(name, lastname, email, password, mobile, cityname);
            this.props.history.push(`/register?success=true`);
        } catch (error) {
            this.setState({ error: error.message });
        }
    };

    render() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.get('success') === 'true') {
            return <RegisterSuccess history={this.props.history}/>;
        }
        return (
            <div className="register login">
                <Header />
                <h2> Registrate </h2>

                {this.state.error ? (
                    <div className="errors">{this.state.error}</div>
                ) : (
                    ''
                )}

                <RegisterForm
                    onRegisterSubmit={this.onRegisterSubmit.bind(this)}
                />
                <p>
                    ¿Tenés una cuenta?
                    <a className="signup" href="/login">
                        Ingresar
                    </a>
                </p>
                <p className="no-margin">
                    <a className="signup"> Términos y Condiciones </a>
                </p>
            </div>
        );
    }
}

Register.propTypes = {
    history: PropTypes.object
};

export default Register;
