import React, { useState } from 'react';
import Navbar from '../common/navbar/Navbar';
import StatusBar from '../common/statusBar/StatusBar';
import './Query.css';

const Query = ({ history }) => {
    const [queryText, setQueryText] = useState('');

    return (
        <div className="query">
            <Navbar history={history} />
            <StatusBar history={history} />
            <div className="query-wrapper">
                <h1 className="query-wrapper__header">
                    Consulta de estado de paquete
                </h1>
                <h3 className="query-wrapper__subheader">
                    Ingrese a continuacion el numero de ID de su paquete
                </h3>
                {/* <h2 className="query-wrapper__input">203938333</h2> */}
                <input
                    type="text"
                    className="query-wrapper__input"
                    value={queryText}
                    onChange={e => setQueryText(e.target.value)}
                />
                <button
                    className={`query-wrapper__button ${
                        queryText === '' ? 'disabled' : ''
                    }`}
                    onClick={() =>
                        history.push(
                            `/order-detail?${'externalCode'}=${queryText}`
                        )
                    }
                    disabled={queryText === ''}
                >
                    CONSULTAR
                </button>

                <div className="square square--query">
                    <div className="square-container">
                        <div className="small-square"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Query;
