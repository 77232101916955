import React from 'react';
import PropTypes from 'prop-types';
import './Login.scss';

class LoginForm extends React.Component {
    state = {
        email: '',
        password: '',
        error: null
    };

    onInputChange = event => {
        this.setState({ [event.target.id]: event.target.value });
    };

    onSubmit = async event => {
        event.preventDefault();
        this.props.onLoginSubmit(this.state.email, this.state.password);
    };

    render() {
        return (
            <form onSubmit={this.onSubmit.bind(this)}>
                <div className="">
                    <div className="input-group email">
                        <label htmlFor="email">Correo electrónico</label>
                        <input
                            id="email"
                            type="email"
                            value={this.state.email}
                            onChange={this.onInputChange}
                            required
                        />
                    </div>
                    <div className="input-group password">
                        <label htmlFor="password">Contraseña</label>
                        <input
                            id="password"
                            type="password"
                            value={this.state.password}
                            onChange={this.onInputChange}
                            autoComplete="on"
                            required
                        />
                    </div>

                    <button type="submit" className="formButton">
                        Ingresar
                    </button>
                </div>
            </form>
        );
    }
}

LoginForm.propTypes = {
    onLoginSubmit: PropTypes.func
};

export default LoginForm;
