import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import Navbar from '../common/navbar/Navbar';
import StatusBar from '../common/statusBar/StatusBar';
import Card from './card/Card';
import HistoryItem from './historyItem/HistoryItem';
import Accordion from './Accordion/Accordion';
import ErrorHandler from '../common/errorHandler/ErrorHandler';
import './Dashboard.css';
import {
    getLastOrders,
    getPackageSizes,
    getPackageStatus,
    getHistory,
    getDeliveryTermList
} from '../../api/paqueryBackend';

const Dashboard = ({ history }) => {
    const [cookies] = useCookies(['token']);
    const [orders, setOrders] = useState([]);
    const [status, setStatus] = useState([]);
    const [deliveryTerm, setDeliveryTermList] = useState([]);
    const [collapsed, setCollapse] = useState(true);
    const [sizes, setSizes] = useState([]);
    const [queryText, setQueryText] = useState('');
    const [dashboardHistory, setDashboardHistory] = useState([]);
    const [error, setError] = useState(null);
    const [ordersLoading, setOrdersLoading] = useState(true);
    const [historyLoading, setHistoryLoading] = useState(true);

    useEffect(() => {
        const getOrders = async () => {
            try {
                if (cookies.token) {
                    const deliveryTerm = await getDeliveryTermList();
                    setDeliveryTermList(deliveryTerm);
                    const status = await getPackageStatus();
                    setStatus(status);
                    const sizes = await getPackageSizes();
                    setSizes(sizes);
                    const orders = await getLastOrders(cookies.token);
                    setOrders(orders);
                    setOrdersLoading(false);
                    const dashboardHistory = await getHistory(cookies.token);
                    setDashboardHistory(dashboardHistory);
                    setHistoryLoading(false);
                }
            } catch (error) {
                setError(error);
            }
        };
        getOrders();
    }, []);

    const showOrders = () => setCollapse(false);
    const hideOrders = () => setCollapse(true);

    const ordersToShow = collapsed ? orders.slice(0, 2) : orders;

    return (
        <div className="dashboard-container container-fluid">
            <Navbar history={history} />
            <StatusBar history={history} />
            {error && <ErrorHandler />}
            {!error && (
                <section className="pedidos">
                    <div className="pedidos-bar">
                        <h3>Mis Pedidos</h3>
                        <div className="pedidos-bar-search">
                            <input
                                className="pedidos-bar__input"
                                type="text"
                                placeholder="PaQuery ID"
                                value={queryText}
                                onChange={e => setQueryText(e.target.value)}
                            />
                            <button
                                className={queryText === '' ? 'disabled' : ''}
                                onClick={() =>
                                    history.push(
                                        `/order-detail?${'externalCode'}=${queryText}`
                                    )
                                }
                                disabled={queryText === ''}
                            >
                                consultar
                            </button>
                        </div>
                    </div>
                </section>
            )}
            {!error && (
                <section className="card-section">
                    {ordersLoading ?
                        <div className="order-wrapper align-center">
                            <h1 className="order-wrapper__heading not-found"> Cargando... </h1>
                        </div> : ''
                    }
                    {!ordersLoading && orders.length === 0 && (
                        <h5 className="message-no-orders">
                            Todavía no hay pedidos que mostrar
                        </h5>
                    )}

                    {ordersToShow.map((order, index) => (
                        <Card
                            order={order}
                            key={index}
                            statusList={status}
                            sizes={sizes}
                            cardClass={'clickable'}
                            history={history}
                            previusView={'dashboard'}
                            deliveryTermList={deliveryTerm}
                        />
                    ))}
                </section>
            )}
            {!error && ordersToShow.length && (
                <section className="collapse-buttons">
                    <button onClick={showOrders} disabled={!collapsed}>
                        Mostrar más
                    </button>
                    <button onClick={hideOrders} disabled={collapsed}>
                        Mostrar menos
                    </button>
                </section>
            )}

            {!error && (
                <section className="history">
                    <div className="history-title">
                        <h3>Mi Historial</h3>
                    </div>

                    <ul className="history-accordion">
                        {dashboardHistory.map((historyItem, index) => (
                            <li key={index}>
                                {' '}
                                <Accordion
                                    historyItem={historyItem}
                                    statusList={status}
                                    sizes={sizes}
                                />
                            </li>
                        ))}
                    </ul>
                    {historyLoading ?
                        <div className="order-wrapper align-center">
                            <h1 className="order-wrapper__heading not-found"> Cargando... </h1>
                        </div> : ''
                    }
                    {!historyLoading && dashboardHistory.length === 0 && (
                        <h5 className="message-no-historial">Todavía no hay historial que mostrar</h5>
                    )}

                    <div className="history-grid-wrapper">
                        {dashboardHistory.length !== 0 && (
                            <div id="row" className="history-top row">
                                <div className="number col-lg-2 col-md-2 col-sm-12 col-xs-12">
                                    <h4>Paquery ID</h4>
                                </div>
                                <div className="address col-lg-2 col-md-3 col-sm-12 col-xs-12">
                                    <h4>destino</h4>
                                </div>

                                <div className="term col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>plazo</h4>
                                </div>
                                <div className="content col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>contenido</h4>
                                </div>

                                <div className="size col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>tamaño</h4>
                                </div>
                                <div className="img-paquer col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>PaQuer</h4>
                                </div>
                                <div className="receiver col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>recibió</h4>
                                </div>
                                <div className="sign col-lg-1 col-md-1 col-sm-12 col-xs-12">
                                    <h4>firma</h4>
                                </div>
                                <div className="delivered col-lg-1 col-md-2 col-sm-12 col-xs-12">
                                    <h4>Estado</h4>
                                </div>
                            </div>
                        )}

                        <ul className="history-itemList">
                            {dashboardHistory.map((historyItem, index) => (
                                <HistoryItem
                                    key={index}
                                    historyItem={historyItem}
                                    statusList={status}
                                    sizes={sizes}
                                    deliveryTermList={deliveryTerm}
                                />
                            ))}
                        </ul>
                    </div>
                </section>
            )}
        </div>
    );
};

export default Dashboard;
