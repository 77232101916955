import React, {useState} from 'react';
import Modal from 'react-modal';
import './styles.css';
import PaquerCard from '../../../mapDisplay/paquerCard/PaquerCard';

const CurrentStatus = ({ status, statusList, paquer, renderAttemptMenu }) => {

    const [showPaquerInfo, setShowPaquerInfo] = useState(false);
    // En poder del paquer, intento de entrega 1 y 2
    const contactWithPaquer = [3, 31, 32];
    const statusName = statusList.find(
        statusItem => status === statusItem.value
    );

    const isArrivingStatus = [7, 3].includes(parseInt(status));
    const isAttemptStatus = [31, 32].includes(parseInt(status));

    const renderExtraData = (component, isSpecificStatus) => {
        if (isSpecificStatus) {
            return component;
        }
        return '';
    };

    return (
        <li className="status-card__list__element --displ">
            <Modal
                isOpen={showPaquerInfo}
                className="Modal"
                overlayClassName="Overlay"
            >
                <PaquerCard
                    paquer={paquer || {}}
                    close={() => setShowPaquerInfo(false)}
                />
            </Modal>
            <div className="square"></div>
            <div
                className={`paquer-state-displ  ${
                    !isArrivingStatus && !isAttemptStatus ? 'display-table' : ''
                }`}
            >
                <div className="paquer-state__timer">
                    <h3>{statusName ? statusName.name : ''}</h3>
                    {/* {renderExtraData(
                        <h4 className="paquer-state__timer__countdown">
                            Tu pedido llega en 20:40:59
                        </h4>
                    , isArrivingStatus)} */}
                </div>
                {renderExtraData(
                    <div className="paquer-state__options">
                        {
                          contactWithPaquer.includes(statusName.value) ?
                          <a onClick={() => setShowPaquerInfo(true)}>contactar con el paquer</a>
                          :
                          <a style ={{cursor: 'unset'}}>contactar con el paquer</a>
                        }
                        <a style ={{color: '#c9c9c9', borderBottom: 'none', cursor: 'unset'}}>reprogramar envío</a>
                    </div>
                , (isArrivingStatus || isAttemptStatus) && paquer )}
                {renderAttemptMenu(status)}
            </div>
        </li>
    );
};

export default CurrentStatus;
