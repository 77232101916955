const config = {
    basicPackage: process.env.REACT_APP_BASIC_PACKAGE,
    backendUrl: process.env.REACT_APP_API_URL,
    packagesService: process.env.REACT_APP_MS_API_URL,
    googleLogin: process.env.REACT_APP_GOOGLE_LOGIN,
    HERE_API_KEY: process.env.REACT_APP_HERE_API_KEY,
    HERE_API_ID: process.env.REACT_APP_HERE_API_ID,
    HERE_API_CODE: process.env.REACT_APP_HERE_API_CODE
};

const getConfig = parameter => {
    const data = config[parameter];
    // if (process.env.NODE_ENV === 'production') {
    //     return process.env[data];
    // }
    return data;
};

export default getConfig;
