import React from 'react';
import './Header.scss';

class Header extends React.Component {
    render() {
        return (
            <div className="header">
                <img src="/images/logo.png" />
                <p>E-commerce Delivery Partner</p>
            </div>
        );
    }
}

export default Header;
