import React, {useState} from 'react';
import moment from 'moment';
import {IoMdMore} from 'react-icons/io';
import './Card.css';
import {Button, Modal, ModalBody, ModalFooter} from "reactstrap";
import { DeliveryTerms } from "../../../api/paqueryBackend";

const Card = ({ history, order, statusList, sizes, cardClass, previusView }) => {
    const size = sizes.find(size => order.packageSize === size.value);
    const status = statusList.find(status => order.status === status.value);
    const deliveryTerm = DeliveryTerms.find(dtl => order.deliveryTerm === dtl.value);
    const MAX_SHOW_TEXT = previusView === 'dashboard' ? 47 : 34;
    const [showModalDetails,setModal] = useState(false);
    const toggle = () => setModal(!showModalDetails);

    function concatCaptionAndDetail() {
        if (order.caption && order.detail)
            return order.caption + ' - ' + order.detail;
        else if (!order.caption)
            return order.detail
        else if(!order.detail)
        return order.caption;
    }
    const captionAndDetail = concatCaptionAndDetail();

    const isUnileverPackage = (order) => {
        const codeMkp = order.code.split("-");
        return codeMkp[0] === '000153';
    };

    return (
        <div
            className={`card ${cardClass}`}
        >
            <div className="card-title">
                <div>
                    {captionAndDetail ? captionAndDetail.length > MAX_SHOW_TEXT ?
                        (
                            <div>
                                <h3>{`${captionAndDetail.substring(0,MAX_SHOW_TEXT)}...`}
                                    <a style ={{color: '#1210b8', borderBottom: 'none', cursor: 'unset'}} onClick={() => setModal(true)}>
                                        Ver más
                                    </a>
                                </h3>
                                    <Modal isOpen={showModalDetails}>
                                            <ModalBody className = "text-justify-black" >
                                                <div className = "row">
                                                        {order.caption}
                                                </div>
                                                <br></br>
                                                <div className = "row">
                                                        {order.detail}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter className = "backCool">
                                                <Button outline block color="white" onClick={toggle}>Cerrar</Button>
                                            </ModalFooter>
                                     </Modal>
                            </div>
                        ) :
                        <h3>{`${captionAndDetail}`}</h3> : ''
                    }
                </div>

                <div className="card-more-dropdown">
                    <button
                        style={{ width: '30px', background: 'transparent' }}
                    >
                        <IoMdMore size={16} />
                    </button>
                    <ul>
                        <li>
                            {' '}
                            <a href="#"> Ver detalle de envío </a>{' '}
                        </li>
                        <li>
                            <a href="#" style ={{color: '#c9c9c9'}}>Reprogramar envio</a>
                        </li>
                        <li>
                            <a href="#"> Editar dirección de destino </a>
                        </li>
                        <li>
                            <a href="#"> Ver en mapa </a>
                        </li>
                        <li style={{ borderBottom: 'none' }}>
                            <a href="#">Contactar PaQuer</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div
                className={`card-product-detail ${cardClass}`}
                onClick={() => {
                    if (history) {
                        history.push(
                            `/order-detail?externalCode=${order.externalCode}`
                        );
                    }
                }}
            >
                <div className="card-image">
                    <div className="default-image">
                        <div className="square">
                            <div className="square-container">
                                <div className="small-square" />
                            </div>
                        </div>
                        <div className="dark-square" />
                    </div>
                    {/* <img src="https://cdn3.iconfinder.com/data/icons/shopping-deliver-part1/64/deliver-512.png" alt="product" /> */}
                    <div className="package-size">
                        <div className="size-square">
                            <p>{size ? size.name[0] : ''}</p>
                        </div>
                        <div className="size-dark-square" />
                    </div>
                </div>
                <div className="card-detail">
                    {' '}
                    <div className="detail-top">
                        <h5>paquery ID</h5>
                        <h2
                            className="card-id"
                            style={{
                                fontSize: '20px',
                                fontWeight: '600',
                                background: 'transparent'
                            }}
                        >
                            {order.externalCode}
                        </h2>
                    </div>{' '}
                    <div className="detail-middle">
                        <div className="service">
                            <h5>servicio</h5>
                            <h2>
                            { isUnileverPackage(order)
                                        ? ''
                                        : deliveryTerm.description
                            }
                            </h2>
                        </div>
                        <div className="state">
                            <h5>estado</h5>
                            <h2>{status ? status.name : ''}</h2>
                        </div>
                    </div>
                    <div className="detail-low">
                        <h5>Fecha Límite de entrega</h5>
                        <h2>
                            {
//                                order.shippingScheduleDestination &&
//                                order.shippingScheduleDestination.scheduledDate ?
//                                isUnileverPackage(order) ? ''
//                                    :moment(order.shippingScheduleDestination.scheduledDate).format('dddd D [de] MMMM, YYYY')
//                                : '-'
                            ''
                            }
                        </h2>
                    </div>
                </div>
            </div>
            <div className="card-destiny">
                <div className="card-destiny-direction">
                    <h5>destino</h5>
                    <h2>
                        {
                            order.shippingScheduleDestination &&
                            order.shippingScheduleDestination.shippingAddress &&
                            order.shippingScheduleDestination.shippingAddress.addressDetail ?
                                order.shippingScheduleDestination.shippingAddress.addressDetail
                                : '-'
                        }
                    </h2>
                </div>
                <div className="card-destiny-map">
                    {' '}
                    {
                        order.geolocation ? (
                                <a href={`/mapDisplay?externalCode=${order.externalCode}`}>
                                    ver en mapa
                                </a>
                            ) : (
                                <a style={{cursor: 'unset', color: 'grey'}}>
                                    ver en mapa
                                </a>
                            )
                    }
                </div>
            </div>
        </div>
    );
};

export default Card;
