import React, { useState } from 'react';
import './AccountWrapper.css';
import { IoIosMenu } from 'react-icons/io';

const AccountWrapper = ({ history }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <div className="session-wrapper">
            <button
                className="account-wrapper__button --create-account"
                onClick={() => history.push('/register')}
            >
                crear cuenta
            </button>
            <button
                className="account-wrapper__button --log-in"
                onClick={() => history.push('/login')}
            >
                ingresar
            </button>
            <div className="card-more-dropdown">
                <button
                    style={{ width: '30px', background: 'transparent' }}
                    onClick={() => setShowDropdown(!showDropdown)}
                >
                    <IoIosMenu color={'#f2f3f7'} />
                </button>
                <ul className={showDropdown ? 'visible' : ''}>
                    <li>
                        {' '}
                        <a href="/login"> Ingresar </a>{' '}
                    </li>
                    <li>
                        <a href="/register">Crear cuenta</a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default AccountWrapper;
